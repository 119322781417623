import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { UserService } from "../../_services/user.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MasterLicenseService } from "src/app/_services/masterlicense.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-license-bundle-modal",
  templateUrl: "./license-bundle-modal.component.html",
  styleUrls: ["./license-bundle-modal.component.scss"],
})
export class LicenseBundleModalComponent implements OnInit {
  constructor(
    private _user: UserService,
    public _dialogRef: MatDialogRef<LicenseBundleModalComponent>,
    private _masterLicense: MasterLicenseService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
  }

  editBundle() {
    var licID = [];
    for (let m of this.data.apps) {
      licID.push(
        this.data.modules_available.filter((x: any) => x.module.name === m)[0]
          .id
      );
    }
    this._masterLicense
      .updateLicenseBundle(
        this.data.name,
        licID,
        this.data.default_issue,
        this.data.id,
        parseInt(this.data.company_id)
      )
      .subscribe(
        (data: any) => {
          this.triggerChange({ action: "editLincese" });

          this._snackBar.open("License bundle updated.", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
          this._dialogRef.close();
        },
        (error: any) => {
          this._snackBar.open("Error! License bundle not updated.", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
        }
      );
  }

  addLinceseBundle() {
    var licID = [];
    for (let m of this.data.apps) {
      licID.push(
        this.data.modules_available.filter((x: any) => x.module.name === m)[0]
          .id
      );
    }
    this._masterLicense
      .createLicenseBundle(
        this.data.name,
        licID,
        this.data.default_issue,
        parseInt(this.data.company_id)
      )
      .subscribe(
        (data: any) => {
          this.triggerChange({ action: "addLincese" });
          this.data.name = null;
          this.data.apps = null;
          this.data.default_issue = false;
          this._snackBar.open("License bundle created.", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
          this._dialogRef.close();
        },
        (error: any) => {
          this._snackBar.open("Error! License bundle not created.", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
        }
      );
  }

  /**
   * An helper function that checkes callback function onChange in data and triggers it if found
   */
  private triggerChange(param: any = null): void {
    if (typeof this.data.onChange === "function") {
      this.data.onChange(param);
    }
  }
}
