import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageService, StorageKey } from 'src/app/_services/storage';

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss']
})
export class DocumentModalComponent implements OnInit {

  public file !:File;
  public uploadURL: string = "";

  public dataDoc: any = {
    name: null,
    description: null,
    extension: null,
    default_issue: false
  }


  @ViewChild('fileUpload') fileUpload: ElementRef = Object.create(null);

  constructor(
    private _user: UserService,
    public _dialogRef: MatDialogRef<DocumentModalComponent>,
    private localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) 
  {
    
  }

  ngOnInit(): void {
  }

  getHideFlag(): boolean {
    const currentUser: any = this.localStorageService.getItem(
      StorageKey.userDetail,
    );
    return currentUser?.show_for_admin_only;
  }

  onChange(event: any) {
    this.file = event.target.files[0];
    this.dataDoc.extension = this.file.name.split('.')[this.file.name.split('.').length - 1];
    
    this._dialogRef.close({data: this.dataDoc, file: this.file});
  }

}
