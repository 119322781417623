import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Branding } from 'src/app/_models/branding';
import { PlatformService } from 'src/app/_services/platform.service';
import { getPogresBarConfig } from './constants';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit {
  @Input() value!: number;
  @Input() total!: number;

  public chartOptions: any;
  constructor(private platformService: PlatformService) {}

  ngOnInit(): void {
    this.chartOptions = getPogresBarConfig(this.value, this.total, this.theme?.appCustomisation?.primary_color);
  }
  get theme(): Branding {
    return this.platformService.branding;
  }
}
