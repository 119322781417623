import { Component } from '@angular/core';
import { Branding } from 'src/app/_models/branding';
import { PlatformService } from 'src/app/_services/platform.service';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent {
	horizontal = false;

  constructor(private platformService: PlatformService) {}

  get theme(): Branding {
    return this.platformService.branding;
  }
}
