import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
  	public _user: UserService, 
  	public _router: Router,
  	public _auth: AuthenticationService,
  	){}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
		if (this._auth.isAuthenticated()){
			let url: string = state.url;
			if(this._auth.isRoleLearnerDev){
				
			if(url.includes('manager/dashboard')){
					this._router.navigate(['/manager/data']);
					return false
				}
			}
			return true
		} else {
			this._router.navigate(['/authentication/login'],);
			return false
		}		
  }

}
