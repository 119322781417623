<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="user-profile" [ngStyle]="{'background': theme?.appCustomisation?.secondary_color}" (click)="navigateTo()">
<!-- <div class="user-profile" style="background: url(assets/images/background/profile-default-splash.png) no-repeat;height: 164px;" (click)="navigateTo()"> -->
    <!-- User profile image -->
    <div class="profile-img">
        <img [src]="user.attributes.profile_image">
        <!--<secured-image [src]="user.attributes.profile_image"></secured-image>-->
    </div>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="profile-text" >
        <div>
            {{ [user.attributes.first_name, user.attributes.last_name].join(' ') }}
          </div>
          <div class="user-email">
            {{ user.attributes.email }}
          </div>
          <div class="roles">
            <ng-container *ngFor="let role of user.user_roles_human">
              <div class="role-item">{{ role }}</div>
            </ng-container>
      
          </div>
    </div>
    <mat-menu #sdprofile="matMenu" class="mymegamenu">
        <button mat-menu-item>
            <mat-icon>settings</mat-icon> Settings
        </button>
        <a mat-menu-item [routerLink]="'/user/myprofile/'">
            <mat-icon>account_box</mat-icon> Profile
        </a>
        <button mat-menu-item>
            <mat-icon>exit_to_app</mat-icon> Sign Out
        </button>
    </mat-menu>
</div>

<mat-nav-list appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems" routerLinkActive="selected" 
    group="{{menuitem.state}}" (click)="scrollToTop()">
        <a appAccordionToggle [routerLink]="[menuitem.state]" *ngIf="menuitem.type === 'link' && menuitem.roleAccess">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink' && menuitem.roleAccess">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink' && menuitem.roleAccess">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate}}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub' && menuitem.roleAccess">
            <mat-icon>{{ menuitem.icon }}</mat-icon>
            <span>{{ menuitem.name | translate }}</span>
            <span fxFlex></span>
            <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub' && menuitem.roleAccess" >
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index; let i = childitem" routerLinkActive="selected">
                <a [routerLink]="['/', menuitem.state, childitem.state ]"  *ngIf="childitem.type === 'link' && childitem.roleAccess" class="relative"
                    routerLinkActive="selected" (click)="itemSelect[i]=j">{{ childitem.name | translate}}</a>
            </mat-list-item>
            <mat-list-item *ngFor="let childitem of menuitem.children; let j=index; let i=childitem" >
                <a href="javascript: void(0);" *ngIf="childitem.type === 'subchild' && childitem.roleAccess" (click)="itemSelect[i]=j"
                    [ngClass]="j==itemSelect[i]? 'selected' : ''">
                    <span>{{ childitem.name | translate }}</span>
                    <span fxFlex></span>
                    <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
                </a>
                <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'  && childitem.roleAccess" >
                    <mat-list-item *ngFor="let child of childitem.subchildren" routerLinkActive="selected">
                        <a [routerLink]="['/', menuitem.state, childitem.state, child.state ]" routerLinkActive="selected" >{{child.name | translate}}</a>
                    </mat-list-item>
                </mat-nav-list>
            </mat-list-item>

        </mat-nav-list>

        <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator' && menuitem.roleAccess">
            <span>{{ menuitem.name | translate }}</span>
        </div>
    </mat-list-item>
</mat-nav-list>