import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatTableDataSource } from '@angular/material/table';
import { GroupsService } from 'src/app/_services/groups.service';

@Component({
  selector: 'app-assign-multi-user-group-modal',
  templateUrl: './assign-multi-user-group-modal.component.html',
  styleUrls: ['./assign-multi-user-group-modal.component.scss']
})
export class AssignMultiUserGroupModalComponent implements OnInit {

  
  isError:any = false;
  companyGroup: any[] = [];
  public displayedColumns = ['name'];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(this.data.selectedUsers);


  constructor(
    public _dialogRef: MatDialogRef<AssignMultiUserGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private groupService: GroupsService,
  ) { }

  ngOnInit(): void {
    this.companyGroup = this.data.groups;
  }

  closeDialog(){
    if(this.data.id){
      this._dialogRef.close({data: this.data});
    } else {
      this.isError = true;
    }
  }
  
  changeHandler(searchText: string) {
    this.getComapnayGroup(searchText)
  }

  getComapnayGroup(searchText: string): void {
    let data = {
      company: parseInt(this.data.companyId),
      page_size: 25,
      search_params: searchText
    };
    this.groupService
      .getComapnyGroup(data, 0)
      .subscribe(
        (data: any) => {
          
          this.companyGroup = data.detail.result || [];
        },
      );
  }

  selectedGroup(group: any) :void{
    this.data.id = group.id
  }
}
