import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map,catchError } from 'rxjs/operators';
import { MasterLicense} from '../_models/user_authentication';

@Injectable({
  providedIn: 'root'
})
export class MasterLicenseService {

  constructor(
    private http: HttpClient,
  ) { }

  getMasterLicense(id: number | null, limit:number, query: string=null, isActive: boolean=true): any {
      if (id){
      return this.http.get<MasterLicense[]>(`${environment.authUrl}/v1/license_management/masterlicense/${id}/?limit=${limit}&query=${query}&isActive=${isActive}`).pipe(
          map(response => {
            return response
          }),
          catchError(error=>throwError(error || "Something went wrong!")));
      } else {
        return this.http.get<MasterLicense[]>(`${environment.authUrl}/v1/license_management/masterlicense/?limit=${limit}&query=${query}&isActive=${isActive}`).pipe(
          map(response => {
            return response
          }),
          catchError(error=>throwError(error || "Something went wrong!")));
      }
  }

  getLicenseBundles(id: number | null, limit:number, query: string=null): any {
      if (id){
      return this.http.get<any[]>(`${environment.authUrl}/v1/license_management/bundles/${id}/?limit=${limit}&query=${query}`).pipe(
          map(response => {
            return response
          }),
          catchError(error=>throwError(error || "Something went wrong!")));
      } else {
        return this.http.get<any>(`${environment.authUrl}/v1/license_management/bundles/?limit=${limit}`).pipe(
          map(response => {
            return response
          }),
          catchError(error=>throwError(error || "Something went wrong!")));
      }
  }

  createLicenseBundle(name: string, apps: string[], default_issue: boolean, id: number | null){
    if (id){
      return this.http.post<any>(`${environment.authUrl}/v1/license_management/bundles/`, {name,apps,default_issue}).pipe(
        map(response => {
          return response
        }),
          catchError(error=>throwError(error || "Something went wrong!"))
      )
    } else {
      return this.http.post<any>(`${environment.authUrl}/v1/license_management/bundles/${id}/`, {name,apps,default_issue}).pipe(
        map(response => {
          return response
        }),
          catchError(error=>throwError(error || "Something went wrong!"))
      )
    }

  }


  updateLicenseBundle(name: string, apps: string[], default_issue: boolean, id: number, company_id: number | null){
    if (company_id){
      return this.http.put<any>(`${environment.authUrl}/v1/license_management/bundles/${id}/${company_id}/`, {name,apps,default_issue}).pipe(
        map(response => {
          return response
        }),
          catchError(error=>throwError(error || "Something went wrong!"))
      )

    } else {
      return this.http.put<any>(`${environment.authUrl}/v1/license_management/bundles/${id}/`, {name,apps,default_issue}).pipe(
        map(response => {
          return response
        }),
          catchError(error=>throwError(error || "Something went wrong!"))
      )
    }
  }

  deleteLicenseBundle(id: number, company_id: number | null){
    if (company_id){
      return this.http.delete<any>(`${environment.authUrl}/v1/license_management/bundles/${id}/${company_id}/`).pipe(
        map(response => {
          return response
        }),
          catchError(error=>throwError(error || "Something went wrong!"))
      )

    } else {
      return this.http.delete<any>(`${environment.authUrl}/v1/license_management/bundles/${id}/`).pipe(
        map(response => {
          return response
        }),
          catchError(error=>throwError(error || "Something went wrong!"))
      )
    }
  }

  createMasterLicense(module: string, expiry: Date | null, max_users: number | null, payment: string, company: string) {
      return this.http.post<any>(`${environment.authUrl}/masterlicense/`, {module,expiry,max_users,payment, company}).pipe(
        map(response => {
          return response
        }),
          catchError(error=>throwError(error || "Something went wrong!"))
      )
    }

  updateMasterLicense(id: string, expiry: Date | null, maximum_issue: number | null, payment_method: string) {
      return this.http.put<any>(`${environment.authUrl}/masterlicense/${id}/`, {expiry,maximum_issue,payment_method}).pipe(
        map(response => {
          return response
        }),
          catchError(error=>throwError(error || "Something went wrong!"))
      )
  }


}
