<mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap">
      <div fxFlex="100">
        <div fxFlex="80" style="text-align: left">
          <h3>Assign to Group</h3>
        </div>
        <div fxFlex="20" style="text-align: right">
          <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
      </div>

      <div fxFlex="100">
        <app-autocomplete
          [options]="companyGroup"
          (onFilter)="changeHandler($event)"
          placeholder="Select Group"
          optionKey="group_name"
          [required]="true"
          (onSelected)="selectedGroup($event)"
        ></app-autocomplete>
        <mat-error *ngIf="!data.manager && isError">
          <strong>Please select group</strong>
        </mat-error>
      </div>
  
      <div class="table-responsive multiple-user-group">
        <table
          mat-table
          [dataSource]="data.selectedUsers || []"
          matSort
          class="table employee-list no-wrap"
        >
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
            <app-user-card [userDetail]="element" [optionKey]="'first_name'"></app-user-card>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
  
      <div fxFlex="100" class="m-t-20">
        <button
          mat-raised-button
          color="primary"
          style="width: 100%"
          (click)="closeDialog()"
        >
          Save
        </button>
      </div>
    </div>
  </mat-dialog-content>
  