<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="user?.attributes?.platform_settings?.direction || 'ltr'" [ngClass]="{'minisidebar': user?.attributes?.platform_settings?.mini_sidebar, 'boxed': user?.attributes?.platform_settings?.boxed_layout, 'danger': danger, 'blue': blue, 'green': green, 'horizontal' : user?.attributes?.platform_settings?.horizontal_layout }">

        <!-- ============================================================== -->
        <!-- Topbar - style you can find in header.scss -->
        <!-- ============================================================== -->
        <mat-toolbar color="primary" class="topbar relative">
            <div class="w-100 fix-width-for-horizontal">
                <!-- ============================================================== -->
                <!-- Logo - style you can find in header.scss -->
                <!-- ============================================================== -->
                <div class="navbar-header">
                    <a class="navbar-brand" href="/">
                        <!-- Logo icon -->
                        <b>
                            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                            <!-- Dark Logo icon -->
                            <img [src]="theme?.logos?.primary" alt="homepage" class="dark-logo" />
                            <!-- Light Logo icon -->
                            <img [src]="theme?.logos?.primary" class="light-logo" alt="homepage" />
                        </b>
                        <!--End Logo icon -->
                    </a>
                </div>
                <!-- ============================================================== -->
                <!-- sidebar toggle (include if client requirement NOT advised      -->
                <!-- ============================================================== -->
                <!--
                <button mat-icon-button (click)="snav.toggle()" class="sidebar-toggle"
                    value="sidebarclosed">
                    <mat-icon>menu</mat-icon>
                </button>
                -->
                <!-- ============================================================== -->
                <!-- Search Bar (include if client requirement NOT advised          -->
                <!-- ============================================================== -->
                <!-- <button mat-icon-button class="srh-btn" (click)="showSearch = !showSearch">
                    <mat-icon>search</mat-icon>
                </button>
                <form class="app-search" [ngClass]="{'show-search': showSearch}">
                    <input type="text" class="form-control" placeholder="Search &amp; enter">
                    <a class="cl-srh-btn" (click)="showSearch = !showSearch">
                        <i class="ti-close"></i>
                    </a>
                </form>-->
                <span fxFlex></span>
                <!-- ============================================================== -->
                <!-- app header component - style you can find in header.scss / header.component.ts-->
                <!-- ============================================================== -->
                <button mat-icon-button class="srh-btn" (click)="openLink();" *ngIf="user.attributes.profiles_disp.length > 1">
                    <mat-icon>switch_account</mat-icon>
                </button>
                <div *ngIf="!user.attributes.platform_settings.horizontal_layout else horizontalheader">
                    <app-vertical-header></app-vertical-header>
                </div>

                <ng-template #horizontalheader>
                    <app-horizontal-header></app-horizontal-header>
                </ng-template>

                <!-- ============================================================== -->
                <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
                <!-- ============================================================== -->
                <button (click)="updateUserSettings(end.opened);end.toggle()" mat-icon-button class="ml-xs overflow-visible">
                    <mat-icon>settings</mat-icon>
                </button>
            </div>

        </mat-toolbar>
        <!-- ============================================================== -->
        <!-- End Topbar - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
            [ngClass]="{'minisidebar': user.attributes.platform_settings.mini_sidebar}">
            <!-- ============================================================== -->
            <!-- Sidebar - style you can find in sidebar.scss -->
            <!-- ============================================================== -->
            <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
                [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true"
                (close)="sidebarOpened = false">

                <perfect-scrollbar *ngIf="!user.attributes.platform_settings.horizontal_layout; else horizontalsidebar" class="scbar" [config]="config">
                    <app-vertical-sidebar class="app-sidebar" [showClass]='status' (notify)="snav.toggle()">
                    </app-vertical-sidebar>
                </perfect-scrollbar>

                <ng-template #horizontalsidebar>
                    <app-horizontal-sidebar></app-horizontal-sidebar>
                </ng-template>
            </mat-sidenav>

            <!-- ============================================================== -->
            <!-- Sidebar - style you can find in sidebar.scss -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
            <!-- ============================================================== -->
            <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
                <div class="scroll">
                    <mat-nav-list>
                        <mat-menu #langMenu="matMenu" class="mymegamenu" style="width: 100% !important;">
                            <button mat-menu-item *ngFor="let lang of translate.getLangs();" [disabled]="lang === translate.currentLang" (click)="translate.use(lang);user.attributes.platform_settings.language = lang;settingChanged = true;">
                                <span>{{ languageHumanize(lang) }}</span>
                            </button>
                        </mat-menu>
                        <mat-menu #dateMenu="matMenu" class="mymegamenu" style="width: 100% !important;">
                            <button mat-menu-item *ngFor="let form of date_formats;" [disabled]="form === user.attributes.platform_settings.date_format" (click)="user.attributes.platform_settings.date_format = form;settingChanged = true;">
                                <span>{{ today | date:form }}</span>
                            </button>
                        </mat-menu>
                        <h3 mat-subheader class="text-uppercase font-weight-bold">Localisation</h3>
                        <mat-list-item [matMenuTriggerFor]="langMenu">
                            <mat-icon>g_translate</mat-icon>&nbsp; Language {{ languageHumanize(translate.currentLang) }}
                        </mat-list-item>
                        <mat-list-item [matMenuTriggerFor]="dateMenu">
                            <mat-icon>date_range</mat-icon>&nbsp; {{ today | date:user.attributes.platform_settings.date_format }}
                        </mat-list-item>
                        <mat-divider></mat-divider>
                        <h3 mat-subheader class="text-uppercase font-weight-bold">Display Settings</h3>
                        <mat-list-item *ngIf="!user.attributes.platform_settings.mini_sidebar">
                            <mat-slide-toggle color="warn" (change)="settingChanged = true;" [(ngModel)]="user.attributes.platform_settings.horizontal_layout">
                                Horizontal Layout</mat-slide-toggle>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-slide-toggle color="warn" (change)="user.attributes.platform_settings.direction = (user.attributes.platform_settings.direction == 'rtl' ? 'ltr' : 'rtl');settingChanged = true;">RTL
                            </mat-slide-toggle>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-slide-toggle color="warn"  (change)="settingChanged = true;" [(ngModel)]="user.attributes.platform_settings.boxed_layout">Boxed Layout</mat-slide-toggle>
                        </mat-list-item>
                        <mat-list-item *ngIf="!user.attributes.platform_settings.horizontal_layout">
                            <mat-slide-toggle  (change)="settingChanged = true;" [(ngModel)]="user.attributes.platform_settings.mini_sidebar">Mini Sidebar</mat-slide-toggle>
                        </mat-list-item>
                        <mat-list-item>
                            <mat-slide-toggle  (change)="settingChanged = true;" [(ngModel)]="user.attributes.platform_settings.dark_mode" (change)="darkClick()">Dark Mode</mat-slide-toggle>
                        </mat-list-item>
                        <ng-container *ngIf="user.attributes.platform_settings.demo_colours">
                            <mat-divider></mat-divider>
                            <h3 mat-subheader class="text-uppercase font-weight-bold">Demo Colours</h3>

                            <mat-list-item>
                                <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false;settingChanged = true;"
                                    class="text-danger">Red
                                </mat-checkbox>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false;settingChanged = true;"
                                    class="text-megna">Teal
                                    Green</mat-checkbox>
                            </mat-list-item>

                            <mat-list-item>
                                <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false;settingChanged = true;"
                                    class="text-info">Blue
                                </mat-checkbox>
                            </mat-list-item>

                        </ng-container>
                    </mat-nav-list>
                </div>
            </mat-sidenav>

            <!-- ============================================================== -->
            <!-- Page container - style you can find in pages.scss -->
            <!-- ============================================================== -->
            <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">

                <app-breadcrumb></app-breadcrumb>
                <div class="page-content">
                    <router-outlet>
                        <app-spinner></app-spinner>
                    </router-outlet>

                </div>
                <app-footer></app-footer>
            </mat-sidenav-content>
            <!-- ============================================================== -->
            <!-- Page container - style you can find in pages.scss -->
            <!-- ============================================================== -->
        </mat-sidenav-container>
    </div>