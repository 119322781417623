import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../_services/user.service";
import { HeadsetService } from "../../_services/headset.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AuthenticationFinalResponse } from "../../_models/user_authentication";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { HeadsetIssueModalComponent } from "../headset-issue-modal/headset-issue-modal.component";
import { Papa } from "ngx-papaparse";
import { AddUserModalComponent } from "../add-user-modal/add-user-modal.component";
import { GroupsService } from "src/app/_services/groups.service";
import { SelectionModel } from "@angular/cdk/collections";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { AssignMultiUserGroupModalComponent } from "../assign-multi-user-group-modal/assign-multi-user-group-modal.component";
import { MasterLicenseService } from "src/app/_services/masterlicense.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-user-manager",
  templateUrl: "./user-manager.component.html",
  styleUrls: ["./user-manager.component.scss"],
})
export class UserManagerComponent implements OnInit {
  public fileUploadStep: number = 0;
  public addUserButton: boolean = true;
  public addSingleUser: boolean = false;
  public welcomeEmail: boolean = true;
  public loading: boolean = true;

  public table_data!: any[];
  public modules_available!: any[];
  public headsetsAvailable!: any[];
  public headsetsPurchased!: any[];
  public activeLicenses: any[] = [];

  public newUsersArray: any[] = [];
  public successfulNewUsers: any[] = [];
  public AvailableGroup: any[] = [];
  public companyGroup: any[] = [];
  public isRoleLearner = this.authService.isRoleLearner;
  isLoading = false;
  isSearchFormSubmitted = false;
  searchUser: any = "";
  GROUP_MANAGER_ROLES = ["Company Learner", "Els Developer"];
  public AvailableUser!: any[];
  @Input("company") company_id: string = "1";
  @Input("roles") availableRoles: any[] = [];
  @Input("modules") availableModules: any[] = [];
  @Input("licenses") licensePool: any[] = [];
  licenseBundles: any[] = [];
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  totalRows = 0;
  public displayedColumns = [
    "select",
    "name",
    "date of joining",
    "last login",
    "welcome",
    "licenses",
    "headsets",
    "status",
    "action",
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.table_data
  );

  public displayedColumnsRole: string[] = ["name", "id"];
  public dataSourceRole = new MatTableDataSource(this.availableRoles);

  public displayedColumnsLicense: string[] = ["name", "id"];
  public dataSourceLicense = new MatTableDataSource(this.activeLicenses);

  public displayedColumnsLicenseBundles: string[] = ["name", "id"];
  public dataSourceLicenseBundles = new MatTableDataSource(this.activeLicenses);

  public displayedColumnsNewUsers: string[] = [
    "email",
    "name",
    "license",
    "role",
    "success",
  ];
  public dataSourceNewUsers = new MatTableDataSource(this.newUsersArray);

  public displayedColumnsHeadset: string[] = ["name", "id"];
  public dataSourceHeadset = new MatTableDataSource(this.headsetsPurchased);

  public formSingleUser: FormGroup = Object.create(null);
  public selection = new SelectionModel<any>(true, []);

  @ViewChild("fileUpload") fileUpload: ElementRef = Object.create(null);

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _user: UserService,
    private _headset: HeadsetService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    public _fb: FormBuilder,
    private _papa: Papa,
    private groupService: GroupsService,
    protected authService: AuthenticationService,
    private _masterLicense: MasterLicenseService
  ) {
    this.formSingleUser = this._fb.group({
      email: [
        null,
        [
          Validators.pattern(
            "[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}"
          ),
          Validators.required,
        ],
      ],
      name: [null, [Validators.required]],
      licenses: [],
      license_bundles: [],
      group: [],
      role: [null, [Validators.required]],
      headset: [],
      address_1: [],
      address_2: [],
      address_3: [],
      address_4: [],
      postcode: [],
      country: [],
    });
  }

  getActiveLincense(): void {
    const datePipe = new DatePipe("en-US");
    this._masterLicense
      .getMasterLicense(parseInt(this.company_id), 10)
      .subscribe((data: any) => {
        if (data.detail.result) {
          let lincese = data.detail.result;
          for (let lic of lincese) {
            if (this.checkLicenseActive(lic)) {
              const formattedExpiry = lic.expiry
                ? datePipe.transform(new Date(lic.expiry), "yyyy-MM-dd")
                : "Indefinite";
              lic.expiry = formattedExpiry;
              this.activeLicenses.push(lic);
            }
          }
          this.dataSourceLicense.data = this.activeLicenses;
        }
      });
  }

  getLicenseBundles(): void {
    this._masterLicense
      .getLicenseBundles(parseInt(this.company_id), this.pageSize)
      .subscribe((data: any) => {
        this.licenseBundles = data.detail.result;
        this.dataSourceLicenseBundles.data = this.licenseBundles;
        var default_issue = [];
        if (this.licenseBundles) {
          for (let lic of this.licenseBundles) {
            if (lic.default_issue) {
              default_issue.push(lic.id);
            }
          }
        }
        this.formSingleUser.controls["license_bundles"].setValue(default_issue);
      });
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.company_id) {
      this.dataSourceRole.data = this.availableRoles;
      this.getActiveLincense();
      this.getLicenseBundles();

      this.getCompanyUser();
      this._headset
        .getAssignedHeadsets(parseInt(this.company_id), 10, 0)
        .subscribe((data: any) => {
          this.headsetsPurchased = data.detail.result;
          this.dataSourceHeadset.data = this.headsetsPurchased;
        });
    }
    if (this.company_id) {
      this.getGroup();
    }
  }

  pageChanged(event: PageEvent): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getCompanyUser();
  }

  getCompanyUser(): void {
    this.isLoading = true;
    this._user
      .getCompanyUsers(
        parseInt(this.company_id),
        this.pageSize,
        this.offset,
        this.searchUser
      )
      .subscribe(
        (data: any) => {
          // this.table_data = data;
          this.table_data = data?.detail?.result;
          this.dataSource.data = this.table_data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = data.detail.count;
          });
          this.isLoading = false;
          this.loading = false;
          this.AvailableUser = this.table_data.filter(
            (user: any) =>
              user.first_name &&
              !user.user_roles_human.some((role: any) =>
                this.GROUP_MANAGER_ROLES.includes(role)
              )
          );
        },
        (error: any) => {
          this.loading = false;
          this.isLoading = false;
        }
      );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  licenseNames(licenses: any[]) {
    var lics = [];
    if (licenses) {
      for (let l of licenses) {
        lics.push(l.module);
      }
    }

    return lics;
  }

  getRoleName(element: string[]) {
    var ret = "-";
    if (
      element.length !== 0 &&
      this.availableRoles?.filter(
        (x: any) => x.name.toLowerCase() === element[0].toLowerCase()
      ).length !== 0
    ) {
      ret = this.availableRoles?.filter(
        (x: any) => x.name.toLowerCase() === element[0].toLowerCase()
      )[0].name;
    }

    return ret;
  }

  issueHeadset(element: any) {
    const dialogRef = this._dialog.open(HeadsetIssueModalComponent, {
      width: "600px",
      data: {
        headsets: this.headsetsPurchased,
        user_addresses: element.addresses,
        issue: {
          headset_id: null,
          address_id: null,
          address_1: null,
          address_2: null,
          address_3: null,
          address_4: null,
          postcode: null,
          country: null,
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data?.issue?.headset_id) {
        var out = {
          name: result.data.issue.name,
          headset_id: result.data.issue.headset_id,
          address_id: result.data.issue.address_id,
          address_1: result.data.issue.address_1,
          address_2: result.data.issue.address_2,
          address_3: result.data.issue.address_3,
          address_4: result.data.issue.address_4,
          postcode: result.data.issue.postcode,
          country: result.data.issue.country,
          company_id: this.company_id,
          user_id: element.id,
        };
        this._headset
          .issueHeadset(
            out.name,
            parseInt(out.headset_id),
            parseInt(out.address_id),
            out.address_1,
            out.address_2,
            out.address_3,
            out.address_4,
            out.postcode,
            out.country,
            parseInt(out.company_id),
            parseInt(out.user_id)
          )
          .subscribe(
            (data: any) => {
              element.headsets.push("1");
              this._snackBar.open("Headset issued.", "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 2000,
              });
            },
            (error: any) => {
              this._snackBar.open("Error headset not issued.", "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 2000,
              });
            }
          );
      }
    });
  }

  resendWelcome(element: any) {
    this.loading = true;
    this._user.resendWelcome(element.id).subscribe(
      (data: any) => {
        element.welcome_email_sent = new Date();
        this.loading = false;
        this._snackBar.open("Welcome email resent.", "Dismiss", {
          horizontalPosition: "center",
          verticalPosition: "top",
          duration: 2000,
        });
      },
      (error: any) => {
        this.loading = false;
        this._snackBar.open("Error! Welcome email not resent.", "Dismiss", {
          horizontalPosition: "center",
          verticalPosition: "top",
          duration: 2000,
        });
      }
    );
  }

  submitSingleUser(): void {
    var users = [];
    let user = {
      email: this.formSingleUser.controls["email"].value,
      name: this.formSingleUser.controls["name"].value,
      licenses: this.formSingleUser.controls["licenses"].value,
      role: this.formSingleUser.controls["role"].value,
      company: this.company_id,
      headset: this.formSingleUser.controls["headset"].value,
      address_1: this.formSingleUser.controls["address_1"].value,
      address_2: this.formSingleUser.controls["address_2"].value,
      address_3: this.formSingleUser.controls["address_3"].value,
      address_4: this.formSingleUser.controls["address_4"].value,
      postcode: this.formSingleUser.controls["postcode"].value,
      country: this.formSingleUser.controls["country"].value,
      license_bundles: this.formSingleUser.controls["license_bundles"].value,
      welcome_email: this.welcomeEmail,
    };

    if (!user.licenses) {
      user.licenses = [];
    }

    if (!user.license_bundles) {
      user.license_bundles = [];
    }

    users.push(user);
    let userData: any = {
      users: users,
    };
    if (this.formSingleUser.controls["group"].value) {
      userData.group_id =
        this.formSingleUser.controls["group"].value.toString();
    }
    this.createUsers(userData);
  }

  createUsers(users: any) {
    if ((users.users[0].licenses.length == 0) && (users.users[0].license_bundles.length == 0)) {
      this._snackBar.open(
        `Either license or license bundle must be selected.`,
        'Dismiss',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 2000,
        }
      );
      return;
    } else {
      for (let lic of this.activeLicenses) {
        if (users.users[0].licenses.includes(lic.id)) {
          if (lic.assigned_licenses_count >= lic.maximum_issue) {
              this._snackBar.open(`"${lic.module.name}" License limit exceeded. Please contact Xelerate Administrator for more licenses`, "Dismiss", {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 2000,
              });
              return
            }
        }
      }
  
      this.loading = true;
      this._user.createUser(users).subscribe(
        (data: any) => {
          this.formSingleUser.reset();
          var default_issue = [];
  
          for (let lic of this.licenseBundles) {
            if (lic.default_issue) {
              default_issue.push(lic.id);
            }
          }
          this.table_data = data;
          this.dataSource.data = this.table_data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.formSingleUser.controls["license_bundles"].setValue(default_issue);
          this.addSingleUser = false;
          this.addUserButton = true;
          this.fileUploadStep = 0;
          this.getCompanyUser();
          this._snackBar.open("User(s) successfully created!", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        },
        (error: any) => {
          this.loading = false;
          this._snackBar.open("Error! User(s) not added.", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      );
    }
  }

  closeSingleUserForm(): void {
    this.formSingleUser.reset();
    this.addSingleUser = false;
    this.addUserButton = true;
    this.getGroup();
  }

  closeUploadUserForm(): void {
    this.fileUploadStep = 0;
    this.addUserButton = true;
    this.getGroup();
  }

  checkLicenseActive(mlic: any): boolean {
    let date_now = new Date();
    let expiry_date = new Date(mlic.expires);

    if (mlic.expires) {
      if (expiry_date < date_now) {
        return false;
      }
    }

    if (
      mlic.maximum_issue &&
      // mlic.maximum_issue < mlic.assigned_licenses.length
      mlic.maximum_issue < mlic.assigned_licenses_count
    ) {
      return false;
    }

    return true;
  }

  onFileSelected(event: any) {
    const files = event.srcElement.files;

    if (files !== null && files !== undefined && files.length > 0) {
      const reader: FileReader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = (e) => {
        const csv = reader.result;
        const results = this._papa.parse(csv as string, { header: false });

        // VALIDATE PARSED CSV FILE
        if (
          results !== null &&
          results !== undefined &&
          results.data !== null &&
          results.data !== undefined &&
          results.data.length > 0 &&
          results.errors.length === 0
        ) {
          // PERFORM OPERATIONS ON PARSED CSV
          let csvTableHeader = results.data[0];

          let csvTableData = [
            ...results.data.slice(1, results.data.length - 1),
          ];

          if (csvTableData.length > 100) {
            this._snackBar.open('Error! Number of users are more than 100.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            return
          }

          let newUsers = [];

          for (let row of csvTableData) {
            var newUser = {
              email: row[0],
              role: row[2],
              name: row[1],
              licenses: row[3]?.split(";"),
              license_bundles: row[4]?.split(";"),
              headset: row[5],
              address_1: row[6],
              address_2: row[7],
              address_3: row[8],
              address_4: row[9],
              postcode: row[10],
              country: row[11],
              email_success:
                /^([a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,})$/.test(
                  row[0]
                ),
              role_success: false,
              license_success: true,
              welcome_email: false,
            };

            if (row[12] == 0) {
              newUser.welcome_email = false;
            } else {
              newUser.welcome_email = true;
            }

            if (this.availableRoles.find((e) => e.id === parseInt(row[2]))) {
              newUser.role_success = true;
            }

            for (let lic of newUser?.licenses) {
              if (!this.activeLicenses.find((e) => e.id === parseInt(lic))) {
                newUser.license_success = false;
              }
            }

            newUsers.push(newUser);
          }

          this.newUsersArray = newUsers;

          this.successfulNewUsers = this.newUsersArray.filter(
            (obj: any) =>
              obj.name &&
              obj.email_success &&
              obj.role_success &&
              obj.license_success
          );

          if (this.newUsersArray.some((user) => !user.name)) {
            this._snackBar.open(
              "Some of the users do not have name. Please update CSV and try again.",
              "Dismiss",
              {
                horizontalPosition: "center",
                verticalPosition: "top",
                duration: 3000,
              }
            );
            return;
          }
          this.dataSourceNewUsers.data = this.newUsersArray;
          this.fileUpload.nativeElement.value = "";
          this.fileUploadStep = 2;
        } else {
          for (let i = 0; i < results.errors.length; i++) {
            console.log("Error Parsing CSV File: ", results.errors[i].message);
          }
        }
      };
    } else {
      console.log("No File Selected");
    }
  }

  submitMultipleUser(): void {
    this.loading = true;
    var users = [];

    for (let uu of this.successfulNewUsers) {
      let user = {
        email: uu.email,
        name: uu.name,
        licenses: uu.licenses,
        role: uu.role,
        company: this.company_id,
        headset: uu.headset,
        address_1: uu.address_1,
        address_2: uu.address_2,
        address_3: uu.address_3,
        address_4: uu.address_4,
        postcode: uu.postcode,
        country: uu.country,
      };

      users.push(user);
    }
    let userData: any = {
      users: users,
    };

    if (this.formSingleUser.controls["group"].value) {
      userData.group_id =
        this.formSingleUser.controls["group"].value.toString();
    }

    this.createUsers(userData);
  }

  resetFileUpload(): void {
    this.fileUpload.nativeElement.value = "";
    this.fileUpload.nativeElement.click();
  }

  suspendUser(element: any) {
    this.loading = true;
    element.is_active = !element.is_active;
    this._user.suspendUser(element.id, 0).subscribe(
      (data: any) => {
        this.loading = false;
        if (element.is_active) {
          this._snackBar.open("User activated", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
        } else {
          this._snackBar.open("User suspended", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
        }
      },
      (error: any) => {
        element.is_active = !element.is_active;
        this.loading = false;
        if (element.is_active) {
          this._snackBar.open("Error! User not activated.", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
        } else {
          this._snackBar.open("Error! User not suspended.", "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 2000,
          });
        }
      }
    );
  }

  openEditDialog(element: any) {
    element.license_bundles = [];
    if (!element?.active_licenses?.length) element.active_licenses = [];

    const dialogRef = this._dialog.open(AddUserModalComponent, {
      width: "400px",
      data: {
        licenses: this.activeLicenses,
        roles: this.availableRoles,
        user: element,
        license_bundles: this.licenseBundles,
        onChange: (params: any = null) => {
          this.getCompanyUser();
        },
      },
    });
  }

  private getGroup(): void {
    let data = {
      company: parseInt(this.company_id),
      page_size: this.pageSize,
    };
    this.groupService.getComapnyGroup(data, this.offset).subscribe(
      (data: any) => {
        this.AvailableGroup = data.detail.result;
        this.companyGroup = data.detail.result;
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  addGroup() {
    const dialogRef = this._dialog.open(AssignMultiUserGroupModalComponent, {
      width: "600px",
      data: {
        id: null,
        groups: this.AvailableGroup,
        allUser: this.AvailableUser,
        selectedUsers: this.selection.selected,
        companyId: this.company_id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.data?.id) {
        this.isLoading = true;
        let userIds: any[] = [];
        this.selection.selected.map((user) => {
          userIds.push(user.id);
        });

        let group = {
          company: this.company_id,
          users: userIds,
        };

        this.groupService.updateGroup(result?.data.id, group).subscribe(
          (data: any) => {
            this.selection.clear();
            this._snackBar.open("Group added successfully.", "Dismiss", {
              horizontalPosition: "center",
              verticalPosition: "top",
              duration: 2000,
            });
            this.isLoading = false;
          },
          (error: any) => {
            this.isLoading = false;
          }
        );
      }
    });
  }

  changeHandler(searchText: string) {
    this.getComapnayGroup(searchText);
  }

  getComapnayGroup(searchText: string): void {
    let data = {
      company: parseInt(this.company_id),
      page_size: 25,
      search_params: searchText,
    };
    this.groupService.getComapnyGroup(data, 0).subscribe((data: any) => {
      this.companyGroup = data.detail.result;
    });
  }

  selectedGroup(group: any): void {
    this.formSingleUser.patchValue({
      group: group.id,
    });
  }

  roleToName(role: any) {
    let roleName = this.availableRoles.find((obj) => obj.id == role);
    return roleName?.name;
  }

  getUser(): void {
    this.loading = true;
    this._user
      .getCompanyUsers(
        parseInt(this.company_id),
        this.pageSize,
        0,
        this.searchUser
      )
      .subscribe((data: any) => {
        this.table_data = data?.detail?.result;
        this.dataSource.data = this.table_data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.detail.count;
        });
        this.isLoading = false;
        this.loading = false;
        this.AvailableUser = this.table_data.filter(
          (user: any) =>
            user.first_name &&
            !user.user_roles_human.some((role: any) =>
              this.GROUP_MANAGER_ROLES.includes(role)
            )
        );
      });
  }

  clearSearch(): void {
    if (!this.isSearchFormSubmitted) {
      this.searchUser = "";
      return;
    }

    this.searchUser = "";
    this.getUser();
    this.isSearchFormSubmitted = false;
  }

  applySearch(): void {
    this.isSearchFormSubmitted = true;
    this.searchUser = this.searchUser.trim();
    this.getUser();
  }
}
