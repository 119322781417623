import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationFirstResponse, AuthenticationFinalResponse, User, CompanyUser, MasterLicense} from '../_models/user_authentication';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map,catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubdomainService {

  constructor(private http: HttpClient) { }

    getSubDomains(company_id: number | null, query: any = null){
      if (company_id){
        return this.http.get<any>(`${environment.authUrl}/v1/license_management/domains/${company_id}/?query=${query}`).pipe(
          map(response => {
            return response
          }),
            catchError(error=>throwError(error || "Something went wrong!"))
        )
      } else {
        return this.http.get<any>(`${environment.authUrl}/v1/license_management/domains/?query=${query}`).pipe(
          map(response => {
            return response
          }),
            catchError(error=>throwError(error || "Something went wrong!"))
        )

      }
    }
}
