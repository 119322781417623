import { Component, OnInit ,  Input,Output, EventEmitter} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable,  } from 'rxjs';
import { map, debounceTime ,skip, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
  
  @Input() options: any[] = [];
  @Input() userIds: any[] = [];
  @Input() placeholder: string = '';
  @Input() optionKey: string = '';
  @Input() managerDetail: any;
  @Input() multi = false;
  @Input() clearOnSelect: boolean = false;
  @Input() isMultiple = false;
  @Input() set required(value: boolean) {
    this._required = value;
    this.completePlaceholder = `${this.placeholder} ${this._required ? '*' : ''}`;
  }
  completePlaceholder = '';
  _required: boolean = false;
  @Output() onFilter = new EventEmitter<string>();
  @Output() onSelected = new EventEmitter<any>();
  @Output() onUnSelected = new EventEmitter<any>();
  myControl = new FormControl('');
  filteredOptions!: Observable<any[]>;

  ngOnInit() {
    
    if(this.managerDetail){

      const manager = this.options.find((option) => {    
        return option.id == this.managerDetail.id
      })
      if(manager){
        this.myControl.patchValue(manager)
      } else {
        this.options.push(this.managerDetail)
        this.myControl.patchValue(this.managerDetail)
      }
    }

    this.myControl.valueChanges
      .pipe(skip(1))
      .pipe(
        debounceTime(200),
        map((event: any) => {
          return event;
        }),
        distinctUntilChanged()
      )
      .subscribe((text: string) => {
        if(typeof text == 'string'){
          this.onFilter.emit(text);
        }
      });
  }

  displayFn(user: any): string {
    return user && user[this.optionKey] ? user[this.optionKey] : '';
  }

  selectionChange(change:any) {
    this.onSelected.emit(change);
    if(this.clearOnSelect) {
      this.myControl.reset();
    }
  }

  optionClicked(event: Event, option: any) {
    event.stopPropagation();
    this.toggleSelection(option);
  }

  toggleSelection(option: any) {    
    if(!this.userIds.includes(option.id)) {
      this.selectionChange(option)
    } else {
      this.userIds = this.userIds.filter((obj:any) => {
        return obj.id !== option.id;
      });
      this.onUnSelected.emit(option);
    }
  }

}
