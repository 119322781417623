import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HeadsetService } from "../../_services/headset.service";
import { MatPaginator , PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationFinalResponse } from '../../_models/user_authentication';


@Component({
  selector: 'app-headset-issue-manager',
  templateUrl: './headset-issue-manager.component.html',
  styleUrls: ['./headset-issue-manager.component.scss']
})
export class HeadsetIssueManagerComponent implements OnInit {

  public table_data: any = [];
  public carriers: any = [];
  public displayedColumns = ['name', 'ordered', 'headset', 'status', 'carrier'];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(this.table_data);
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  loading= false;
  isSearchFormSubmitted = false;
  searchIssuedHeadsets: any = "";
  @Input('company') company_id: string = "1";

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _headset: HeadsetService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.company_id){
     this.getIssuedHeadsets();
    }
  }

  getIssuedHeadsets():void {
    this.loading = true;
    this._headset.getIssuedHeadsets(parseInt(this.company_id), this.pageSize, this.offset, this.searchIssuedHeadsets).subscribe(
      (data:any) => {                
        this.table_data = data.detail.result;
        this.dataSource.data = this.table_data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.carriers = data.carriers;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.detail.count;
        });
        this.loading = false;
      },
      (error: any) => {
        this.snackBar.open('Error while Loading Data.', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 2000,
        });
        this.loading = false;
      },
    );
  }

  pageChanged(event: PageEvent): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getIssuedHeadsets();
  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  clearSearch(): void {
    if (!this.isSearchFormSubmitted) {
      this.searchIssuedHeadsets = "";
      return;
    }

    this.searchIssuedHeadsets = "";
     this.getIssuedHeadsets()
    this.isSearchFormSubmitted = false;
  }

  applySearch(): void {
    this.isSearchFormSubmitted = true;
    this.searchIssuedHeadsets = this.searchIssuedHeadsets.trim()
     this.getIssuedHeadsets()
  }

}
