import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(private http: HttpClient) {}

  getComapnyGroup(data:any, offset: number) {

    return this.http
      .post<any>(`${environment.authUrl}/company_group/get_groups/?offset=${offset}`, data)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getComapnyManager(company_id: number, data = {}) {
    return this.http
      .post<any>(
        `${environment.authUrl}/company_group/${company_id}/get_managers/`,data
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  createGroup(data: any) {
    return this.http
      .post<any>(`${environment.authUrl}/company_group/create/`, data)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  getGroupUser(data: any) {
    return this.http
      .post<any>(`${environment.authUrl}/company_group/users/`, data)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

  updateGroup(group_id: any, data: any) {
    return this.http
      .patch<any>(`${environment.authUrl}/company_group/${group_id}/`, data)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }
  
  deleteGroup(group_id: any, data: any) {
    
    return this.http
      .post<any>(`${environment.authUrl}/company_group/${group_id}/delete/`, data)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }
  
  mergeGroup(data: any) {
    
    return this.http
      .post<any>(`${environment.authUrl}/company_group/merge_groups/`, data)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!'))
      );
  }

}
