import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'secured-image',
  template: `
  	<span class="mat-button-wrapper">
    	<img [src]="dataUrl$|async" class="{{ classes }}" width="{{ width }}"/>
    </span>
  `
})

export class SecuredImageComponent implements OnChanges  {
  // This code block just creates an rxjs stream from the src
  // this makes sure that we can handle source changes
  // or even when the component gets destroyed
  // So basically turn src into src$
  @Input('src') private src !: string;
  @Input('classes') public classes : string | null = null;
  @Input('width') public width: number | null = null;

  private src$ = new BehaviorSubject(this.src);

  ngOnChanges(): void {
    this.src$.next(this.src);
  }

  // this stream will contain the actual url that our img tag will load
  // everytime the src changes, the previous call would be canceled and the
  // new resource would be loaded
  dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));
  
  // we need HttpClient to load the image
  constructor(
  	private httpClient: HttpClient,
  	private domSanitizer: DomSanitizer) 
  {
  	console.log(this.width)
  }

  private loadImage(url: string): Observable<any> {
  	console.log(this.dataUrl$)
    return this.httpClient
      // load the image as a blob
      .get(url, {responseType: 'blob'}).pipe(
      // create an object url of that blob that we can use in the src attribute
      	map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e)))
      );
  }
}
