import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupsService } from 'src/app/_services/groups.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-merge-group-modal",
  templateUrl: "./merge-group-modal.component.html",
  styleUrls: ["./merge-group-modal.component.scss"],
})
export class MergeGroupModalComponent implements OnInit {
  public displayedColumns = ['name'];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.data.selectedGroup
  );
  public selection = new SelectionModel<any>(true, []);

  public groupUser: any[] = [];
  loading = false;
  isLoading = false;
  newGroupName = '';
  manager: any = null;
  sourceGroup :any[]= [];
  targetGroup :any[]= [];
  companyManager :any[]= [];
  isError: any = false;
  optionKey = 'first_name'
  moveGroup: any = {
    sourceGroup: null,
    targetGroup: null,
  };
  constructor(
    private groupService: GroupsService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MergeGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;

    this.sourceGroup = data.companyGroup;
  }

  ngOnInit(): void {
    this.companyManager = this.data.AvailableManager;
    this.newGroupName = this.data.selectedGroup.map((value:any) => value.group_name).join(" - ") ;
  }

  closeDialog() {
    this.dialogRef.close({ data: this.data });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.groupUser.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.groupUser);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  mergeGroup() {
    if (this.manager && this.newGroupName) {
      this.isLoading = true;
      let groupIds: any[] = [];
      this.data.selectedGroup.map((group: any) => {
        groupIds.push(group.id);
      });
      let mergeData = {
        company_id: this.data.companyId,
        group_ids: groupIds,
        manager: this.manager,
        group_name : this.newGroupName
      };
      this.groupService.mergeGroup(mergeData).subscribe(
        (data: any) => {
          this.isLoading = false;
          this.closeDialog();
        },
        (error: any) => {
          this.isLoading = false;
          this.snackBar.open(error?.error?.message, "Dismiss", {
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      );
    } else {
      this.isError = true;
    }
  }

  changeHandler(searchText: string) {
    this.getComapnayManager(searchText)
  }
  getComapnayManager(searchText: string): void {
    let search = {}
    if(searchText) {

      search = {
        "search_params": searchText,
      }
    }
    this.groupService.getComapnyManager(parseInt(this.data.companyId), search).subscribe(
      (data: any) => {
        this.companyManager = data.detail.result;
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }
  getManager(manager: any) :void{
    this.manager  = manager.id
  }
}
