import { Injectable } from "@angular/core";
import { User } from "./_models/user_authentication";
import { PlatformService } from "./_services/platform.service";
import { LocalStorageService, StorageKey } from "./_services/storage";

@Injectable()
export class AppProvider {
  constructor(
    private platformService: PlatformService,
    private localStorageService: LocalStorageService
  ) {
    console.log("initialzed");
  }
  /**
   * @method
   * @name resolveDependencies
   *
   * @description this will resolve dependencies before application bootstrap
   */
  resolveDependencies(): any {
    let domain = window.location.host.replace("www.", "");
    domain = domain.startsWith("localhost")
      ? "deloitte.xeleratelearning.com"
      : domain;
    return new Promise<void>((resolve, reject) => {
      const user: any = this.localStorageService.getItem(StorageKey.userDetail);
      const settings = user?.company;
      if (settings) {
        const { logo_read_url, app_customisation, name } = settings;
        this.platformService.setBranding({
          app_customisation,
          logo_read_url,
          name,
        });
        resolve();
        return;
      }

      this.platformService
        .getDefaultSettings({
          domain,
        })
        .subscribe({
          next: ({ detail }) => {
            const settings = user?.company ? user.company : detail;
            const { logo_read_url, app_customisation, name } = settings;
            this.platformService.setBranding({
              app_customisation,
              logo_read_url,
              name,
            });
            resolve();
          },
          error: (err: any) => {
            reject();
            console.log("Unable to load branding settings", err);
          },
        });
    });
  }
}
