<mat-dialog-content class="mat-typography">
    <div fxLayout="row wrap">
      <div fxFlex="100" >
        <div fxFlex="80" style="text-align: left">
          <h3>Move User</h3>
        </div>
        <div fxFlex="20" style="text-align: right">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
      </div>
  
      <div fxFlex="100">
        <div fxFlex="50" style="margin-right: 10px;">
          <app-autocomplete
            [options]="sourceGroup"
            (onFilter)="changeHandler($event, 'source')"
            placeholder="Source Group"
            optionKey="group_name"
            [required]="true"
            (onSelected)="getUser($event)"
          ></app-autocomplete>
          <mat-error *ngIf="!moveGroup.sourceGroup && isError">
            <strong>Please Select Source group</strong>
          </mat-error>
        </div>

        <div fxFlex="50" style="margin-right: 10px;">
          <app-autocomplete
            [options]="targetGroup"
            (onFilter)="changeHandler($event, 'target')"
            placeholder="Target Group"
            optionKey="group_name"
            [required]="true"
            (onSelected)="selectedTargetGroup($event)"
          ></app-autocomplete>
          <mat-error *ngIf="!moveGroup.targetGroup && isError">
            <strong>Please Select Target Group</strong>
          </mat-error>
        </div>

      </div>
  
      <mat-progress-bar
        mode="indeterminate"
        class="m-t-20"
        *ngIf="loading"
      ></mat-progress-bar>
  
      <div style="margin-top: 5px;" class="mat-error"  *ngIf="!moveGroup.sourceGroup && !loading">
          <p>Please select Source group to move users to Target group</p>
      </div>
      <div style="margin-top: 5px;" class="mat-error" *ngIf="!groupUser.length && moveGroup.sourceGroup && !loading && !isError">
          <p>Selected source group does not have any user associated to it.</p>
      </div>
      <div style="margin-top: 5px;" class="mat-error" *ngIf="!selection.selected.length && isError && moveGroup.sourceGroup && moveGroup.targetGroup">
          <p>Please select user which you want to move.</p>
      </div>
      <div class="table-responsive multiple-user-group" *ngIf="groupUser.length">
        <table
          mat-table
          [dataSource]="groupUser"
          matSort
          class="table employee-list no-wrap"
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
            <app-user-card [userDetail]="element" [optionKey]="'first_name'"></app-user-card>

            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
  
      <div fxFlex="100" class="m-t-20 d-flex">
        <button
          mat-raised-button
          color="primary"
          style="width: 100%"
          (click)="closeDialog()"
        >
          Move to Target Group
        </button>
      </div>
    </div>
  </mat-dialog-content>
  