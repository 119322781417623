<mat-dialog-content class="mat-typography">
  <div fxLayout="row wrap" style="position: relative;">
    <div fxFlex="100">
      <div fxFlex="80" style="text-align: left">
        <h3 *ngIf="data.id">Edit Group</h3>
        <h3 *ngIf="!data.id">Create Group</h3>
      </div>
      <div fxFlex="20" style="text-align: right">
        <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
      </div>
    </div>
    <div fxFlex="100">
      <mat-form-field>
        <mat-label>Group Name</mat-label>
        <input matInput type="text" [(ngModel)]="data.group_name" required />
      </mat-form-field>
      <mat-error class="text-danger" *ngIf="!data.group_name && isError">
        <strong>Enter group name</strong>
      </mat-error>
    </div>

    <div fxFlex="100">
      <app-autocomplete
        [options]="companyManager"
        (onFilter)="changeHandler($event)"
        placeholder="Manager of Group"
        [optionKey]="optionKey"
        [required]="true"
        (onSelected)="getManager($event)"
        [managerDetail]="data.manager"
      ></app-autocomplete>
      <mat-error class="text-danger" *ngIf="!data.manager && isError">
        <strong>Please select Manager</strong>
      </mat-error>
    </div>

    <div fxFlex="100"  *ngIf="users.length ">
      <app-autocomplete
        [options]="companyUser"
        (onFilter)="changeHandlerUser($event)"
        placeholder="select user"
        [optionKey]="'first_name'"
        [required]="false"
        (onSelected)="addUser($event)"
        [clearOnSelect]="true"
        [userIds]="groupUserIds"
        [selectedOptions] = 'groupUserIds'
        [isMultiple]="true"
        (onUnSelected) ="removeUser($event)"
      ></app-autocomplete>
    </div>

    <mat-progress-bar
      mode="indeterminate"
      class="m-t-20"
      *ngIf="loading && !groupUser.length || !users.length"
    ></mat-progress-bar>

    <div class="table-responsive multiple-user-group" *ngIf="groupUser.length">
      <table
        mat-table
        [dataSource]="groupUser || []"
        matSort
        class="table employee-list no-wrap"
      >
        <ng-container matColumnDef="select" *ngIf="data.id">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <app-user-card [userDetail]="element" [optionKey]="'first_name'"></app-user-card>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element" class="action-link">
            <button
              mat-mini-fab
              color="warn"
              (click)="removeSingleUser(element)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <div fxFlex="100" class="m-t-20 d-flex">
      <button
        mat-raised-button
        color="primary"
        style="width: 50%; margin-right: 10px; justify-content: center"
        (click)="removeMultiUser()"
        *ngIf="selection.selected.length"
      >
        <mat-icon>delete</mat-icon>Remove users
      </button>
      <button
        mat-raised-button
        color="primary"
        style="display: flex; flex-grow: 1; justify-content: center"
        (click)="updateGroup()"
        *ngIf="data.id"
      >
        <mat-icon>update</mat-icon>Update Group
      </button>
      <button
        mat-raised-button
        color="primary"
        style="width: 100%"
        (click)="createGroup()"
        *ngIf="!data.id"
      >
        <mat-icon>post_add</mat-icon>Create Group
      </button>
    </div>
    <app-loader *ngIf="isLoading" [size]="'small'"></app-loader>
  </div>
</mat-dialog-content>

