import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { GroupsService } from 'src/app/_services/groups.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: "app-move-user-another-group",
  templateUrl: "./move-user-another-group.component.html",
  styleUrls: ["./move-user-another-group.component.scss"],
})
export class MoveUserAnotherGroupComponent implements OnInit {
  public displayedColumns = ['select', 'name'];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.data.selectedUsers
  );
  public selection = new SelectionModel<any>(true, []);

  public groupUser: any[] = [];
  loading = false;

  sourceGroup:any[] = [];
  targetGroup :any[] = [];
  companyGroup :any[] = [];
  isError: any = false;

  moveGroup = {
    sourceGroup: '',
    targetGroup: '',
  };
  constructor(
    private groupService: GroupsService,
    public dialogRef: MatDialogRef<MoveUserAnotherGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;

    this.sourceGroup = data.companyGroup;
    this.companyGroup = data.companyGroup;
  }

  ngOnInit(): void {}

  closeDialog() {
    if (this.moveGroup.targetGroup && this.selection.selected.length) {
      this.data.moveGroup = this.moveGroup;
      let userIds: any[] = [];
      this.selection.selected.map((user) => {
        userIds.push(user.id);
      });
      this.data.moveUser = userIds;
      this.dialogRef.close({ data: this.data });
    } else {
      this.isError = true;
    }
  }

  getUser(ob: any) {
    this.loading = true;
    this.moveGroup.sourceGroup = ob.id;
    
    let newtargetGroup = this.companyGroup.filter((group: any) => {
      return group.id != ob.id;
    });
    
    this.targetGroup = newtargetGroup;
    let groupData = {
      group_ids: [ob.id],
      company_id: this.data.companyId,
      page_size: 99999,
      group_members: true,
    };
    this.groupService.getGroupUser(groupData).subscribe((data: any) => {
      this.groupUser = data.detail.result;
      this.loading = false;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.groupUser.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.groupUser);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  changeHandler(searchText: string, type:string) {
    this.getComapnayGroup(searchText, type)
  }

  getComapnayGroup(searchText: string, type:string): void {
    let data = {
      company: parseInt(this.data.companyId),
      page_size: 25,
      search_params: searchText
    };
    this.groupService
      .getComapnyGroup(data, 0)
      .subscribe(
        (data: any) => {
          if(type == 'source') {
            this.sourceGroup = data.detail.result;
          } else {
            this.targetGroup = data.detail.result.filter((group: any) => {
              return group.id != this.moveGroup.sourceGroup;
            });
          }
        },
      );
  }

  selectedTargetGroup(group: any) :void{
    this.moveGroup.targetGroup = group.id
  }
}
