import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { SubdomainService } from "../../_services/subdomain.service";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-subdomains',
  templateUrl: './subdomains.component.html',
  styleUrls: ['./subdomains.component.scss']
})
export class SubdomainsComponent implements OnInit {

  public table_data !: any[];
  public displayedColumns = ['subdomain', 'domain', 'restricted', 'custom', 'created'];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(this.table_data);
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  isSearchFormSubmitted = false;
  searchSubDomain: any = "";
  @Input('company') company_id: string = "1";
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator = Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _subdomain: SubdomainService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.getSubDomains();
  }

  getSubDomains():void {
    this._subdomain.getSubDomains(parseInt(this.company_id), this.searchSubDomain).subscribe(
      (data:any) => {        
        this.table_data = data.detail.result;
        this.dataSource.data = this.table_data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.detail.count;
        });
      }
    )
  }

  pageChanged(event: PageEvent): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getSubDomains();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string): void {
     filterValue = filterValue.trim(); 
     filterValue = filterValue.toLowerCase(); 
     this.dataSource.filter = filterValue;
  }

  getDomainType(domain: string){
    if (domain.includes('e-learningstudios')){
      return 'E-Learning Studios'
    } 

    if (domain.includes('xelerate')){
      return 'Xelerate'
    } 

    if (domain.includes('xrlplatform')){
      return 'XRL Platform'
    } 
  }

  clearSearch(): void {
    if (!this.isSearchFormSubmitted) {
      this.searchSubDomain = "";
      return;
    }

    this.searchSubDomain = "";
    this.getSubDomains();
    this.isSearchFormSubmitted = false;
  }

  applySearch(): void {
    this.isSearchFormSubmitted = true;
    this.searchSubDomain = this.searchSubDomain.trim()
    this.getSubDomains();
  }
}
