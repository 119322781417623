import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../_services/user.service";
import { HeadsetService } from "../../_services/headset.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AuthenticationFinalResponse } from "../../_models/user_authentication";
import { HeadsetModalComponent } from "../headset-modal/headset-modal.component";

@Component({
  selector: "app-headset-manager",
  templateUrl: "./headset-manager.component.html",
  styleUrls: ["./headset-manager.component.scss"],
})
export class HeadsetManagerComponent implements OnInit {
  public headsetsAvailable!: any[];
  public headsetsPurchased!: any[];
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  loading = false;
  isSearchFormSubmitted = false;
  searchAssignedHeadsets: any = "";
  public displayedColumns = [
    "name",
    "date",
    "quantity_purchased",
    "quantity_used",
    "quantity_remaining",
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource(
    this.headsetsPurchased
  );

  @Input("company") company_id: string = "1";

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild(MatSort, { static: true }) sort: MatSort = Object.create(null);

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _headset: HeadsetService,
    private snackBar: MatSnackBar,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.company_id) {
      this.getAssignedHeadsets();
    }
  }

  getAssignedHeadsets(): void {
    this.loading = true;
    this._headset
      .getAssignedHeadsets(
        parseInt(this.company_id),
        this.pageSize,
        this.offset,
        this.searchAssignedHeadsets
      )
      .subscribe((data: any) => {
        this.headsetsPurchased = data.detail.result;
        this.dataSource.data = this.headsetsPurchased;
        this.dataSource.paginator = this.paginator;
        setTimeout(() => {
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = data.detail.count;
        });
        this.loading = false;
      },
      (error: any) => {
        this.snackBar.open('Error while Loading Data.', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 2000,
        });
        this.loading = false;
      },
    );
  }

  pageChanged(event: PageEvent): void {
    this.offset = event.pageSize * event.pageIndex;
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAssignedHeadsets();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  clearSearch(): void {
    if (!this.isSearchFormSubmitted) {
      this.searchAssignedHeadsets = "";
      return;
    }

    this.searchAssignedHeadsets = "";
     this.getAssignedHeadsets()
    this.isSearchFormSubmitted = false;
  }

  applySearch(): void {
    this.isSearchFormSubmitted = true;
    this.searchAssignedHeadsets = this.searchAssignedHeadsets.trim()
     this.getAssignedHeadsets()
  }
}
