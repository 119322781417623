import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PlatformService } from '../../_services/platform.service';
import { UserService } from '../../_services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocalStorageService , StorageKey} from 'src/app/_services/storage';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-platform-settings',
  templateUrl: './platform-settings.component.html',
  styleUrls: ['./platform-settings.component.scss'],
})
export class PlatformSettingsComponent implements OnInit, OnChanges {
  @Input() company: string;
  @Input() companyName!: string;

  platformData!: any;
  primaryUploading = false;
  secondaryUploading = false;
  debouceColorChange = new Subject<string>();
  constructor(
    private platformService: PlatformService,
    private snackBarService: MatSnackBar,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    protected authService: AuthenticationService
  ) {
    this.debouceColorChange.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.onColourChange();
      });

  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.company) {
      this.platformService
        .getPlatformSettings(parseInt(this.company))
        .subscribe((data: any) => {
          this.platformData = data.detail;
        });
    }
  }

  onColourChange(): void {
    if (
      this.platformData.primary_color.length === 7 &&
      this.platformData.secondary_color.length === 7 &&
      this.platformData.warn_color.length === 7
    ) {
      this.platformService
        .updatePlatformColours(
          this.platformData.primary_color,
          this.platformData.secondary_color,
          this.platformData.warn_color,
          parseInt(this.company),
        )
        .subscribe(
          (data: any) => {
            this.platformData = data.detail;
            this.setPlatformSettings(this.platformData);
            this.snackBarService.open('Platform settings updated.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
          },
          (error: any) => {
            this.platformService
              .getPlatformSettings(parseInt(this.company))
              .subscribe((data: any) => {
                this.platformData = data.detail;
                this.setPlatformSettings(data.detail);
              });
            this.snackBarService.open(
              'Error platform settings not updated.',
              'Dismiss',
              {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              },
            );
          },
        );
    }
  }

  private setPlatformSettings(settings: any): void {
    const { logos, name, ...rest } = settings;
    let platformSetting = {
      app_customisation: rest,
      logo_read_url: logos,
    }
    this.platformService.setBranding({
      ...platformSetting,
      name,
    });
    const user: any = this.localStorageService.getItem(StorageKey.userDetail);
    let isCompanyAdmin = user.user_roles_human.includes('Company Administrator')
    if(isCompanyAdmin){     

      let userCompany = {
        ...user.company,
      ...platformSetting
    };
    if(settings.name){
      userCompany.name = settings.name
    }
    
    this.localStorageService.setItem(
      StorageKey.userDetail,
      {
        ...user,
        company: userCompany,
      }
      );
    }
  }

  onFileSelected(event: any, logo: string): void {
    const file: File = event.target.files[0];

    if (logo === 'primary') {
      this.primaryUploading = true;
    } else {
      this.secondaryUploading = true;
    }

    if (file) {
      this.userService
        .fileUploadService(file, `platform/${logo}/${this.company}`)
        .subscribe(
          (data: any) => {
            // console.log('fileUploadService', data);

            this.platformData = data;
            this.snackBarService.open('Platform settings updated.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
            if (logo === 'primary') {
              this.primaryUploading = false;
            } else {
              this.secondaryUploading = false;
            }
          },
          (error: any) => {
            if (logo === 'primary') {
              this.primaryUploading = false;
            } else {
              this.secondaryUploading = false;
            }
            this.snackBarService.open(
              'Error platform settings not updated.',
              'Dismiss',
              {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 2000,
              },
            );
          },
        );
    }
  }
}
