/**
 * @author Jack Paget
 * @created 27/12/2021
 * @updated 27/12/2021
 * */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {
  AuthenticationFirstResponse,
  AuthenticationFinalResponse,
  AuthorisationResponse,
} from '../_models/user_authentication';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { UserService } from './user.service';
import { LocalStorageService, StorageKey } from './storage';

const LEARNER_ROLES = ['Company Learner'];
const LEARNER_dev_ROLES = ['Company Learner', 'Els Developer'];

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private mfaDetails: AuthenticationFirstResponse = Object.create(null);
  private userDetails: AuthenticationFinalResponse = Object.create(null);

  constructor(
    private _http: HttpClient,
    private _user: UserService,
    private _router: Router,
    private _cookieService: CookieService,
    private localStorageService: LocalStorageService,
  ) {
    this._user.currentMFADetails.subscribe(
      (data: AuthenticationFirstResponse) => {
        this.mfaDetails = data;
      },
    );
    this._user.currentUserDetails.subscribe(
      (data: AuthenticationFinalResponse) => {
        this.userDetails = data;
      },
    );
  }

  getUserRoles() {
    return this._http
      .get<any>(`${environment.authUrl}/v1/company/roles_list/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || "Something went wrong!"))
      );
  }
  
  getCompanies() {
    return this._http
    .get<any>(`${environment.authUrl}/v1/company/companies/`)
    .pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => throwError(error || "Something went wrong!"))
    );
  }

  getApplications(): any {
    return this._http
      .get<any>(`${environment.authUrl}/v1/company/applications/`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || "Something went wrong!"))
      );
  }

  ssaiUserRegister(user: any) {
    return this._http.post<any>(`${environment.authUrl}/v1/ssai_create_user/`, user).pipe(
      map((response)=> {
        return response
      })
    )
  }

  initialRegistration(
    email: string | null,
    name: string | null,
    company: boolean | null,
  ): any {
    return this._http
      .post<AuthenticationFirstResponse>(`${environment.authUrl}/register/`, {
        email,
        name,
        company,
      })
      .pipe(
        map((response) => {
          localStorage.setItem(
            'authenticationDetails',
            JSON.stringify(response),
          );
          this._user.changeMFADetails(response);
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!')),
      );
  }

  // renew authorisation token.

  authorizeRenewal(): any {
    return this._http
      .post<AuthorisationResponse>(`${environment.authUrl}/authorize/`, {})
      .pipe(
        map((response) => {
          let userDetails = JSON.parse(
            localStorage.getItem('userDetails') || '{}',
          );
          userDetails.authorisation_token = response.authorisation_token;
          userDetails.attributes = response.attributes;

          const helper = new JwtHelperService();

          localStorage.setItem('userDetails', JSON.stringify(userDetails));
          this._user.changeUserDetails(userDetails);

          let auth_expir = helper.getTokenExpirationDate(
            response.authorisation_token,
          );
          let now = new Date();
          if (auth_expir) {
            let seconds_to = auth_expir.getTime() - now.getTime();
            this.refreshAuthorisationToken(seconds_to - 20000);
          }
          return 'Updated';
        }),
        catchError((error) => throwError(error || 'Something went wrong!')),
      );
  }

  // initial authentication with server to determine authentication method.

  initialAuthentication(
    email: string | null,
    pin: string | null,
    password_enabled_override: boolean,
  ): any {
    return this._http
      .post<AuthenticationFirstResponse>(`${environment.authUrl}/session/`, {
        email,
        pin,
        password_enabled_override,
      })
      .pipe(
        map((response) => {
          localStorage.setItem(
            'authenticationDetails',
            JSON.stringify(response),
          );
          this._user.changeMFADetails(response);
          this.mfaDetails = response;
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!')),
      );
  }

  // final authentication with server
  finalAuthentication(
    email: string | null,
    code: string | null,
    password: string | null,
  ): any {
    let mfa_token = this.mfaDetails.mfa_token;
    return this._http
      .post<AuthenticationFinalResponse>(`${environment.authUrl}/session/`, {
        email,
        code,
        password,
        mfa_token,
      })
      .pipe(
        map((response) => {
          const helper = new JwtHelperService();
          if (response.session_token) {
            localStorage.setItem('userDetails', JSON.stringify(response));

            const loc = window.location;
            this._user.changeUserDetails(response);
            localStorage.setItem('mfaDetails', JSON.stringify({}));
            let auth_expir = helper.getTokenExpirationDate(
              response.authorisation_token,
            );
            let now = new Date();

            if (auth_expir) {
              let seconds_to = auth_expir.getTime() - now.getTime();
              this.refreshAuthorisationToken(seconds_to - 20000);
            }

            this._user.changeMFADetails({});
          }
          return response;
        }),
        catchError((error) => throwError(error || 'Something went wrong!')),
      );
  }

  // check if user is has an MFA token

  isMFAAuthenticated(): boolean {
    let details = JSON.parse(
      localStorage.getItem('authenticationDetails') || '{}',
    );
    if (details.mfa_token) {
      return true;
    }
    return false;
  }

  // log the user out

  logOutUser(): void {
    localStorage.setItem('userDetails', JSON.stringify({}));
    this._user.changeUserDetails({});
    this.localStorageService.removeItem(StorageKey.companyId);
    this.localStorageService.removeItem(StorageKey.appId);
    this.localStorageService.removeItem(StorageKey.groupId);
    this._router.navigate(['/authentication/login']);
  }

  // authorisation tokens are short lived refreshed when needed required

  refreshAuthorisationToken(refreshIn: number) {
    setTimeout(() => {
      this.authorizeRenewal().subscribe(
        (response: any) => {},
        (error: any) => {
          this.logOutUser();
        },
      );
    }, refreshIn);
  }

  // return true is the user has tokens and they have not expired.

  isAuthenticated(): boolean {
    const helper = new JwtHelperService();
    if (
      this.userDetails.session_token &&
      !helper.isTokenExpired(this.userDetails.session_token)
    ) {
      return true;
    }
    return false;
  }

  isAuthAuthenticated(): boolean {
    const helper = new JwtHelperService();
    if (this.userDetails.authorisation_token && !helper.isTokenExpired(this.userDetails.authorisation_token)){
      return true
    }
    return false
}

  getSessionToken(): any {
    return this.userDetails.session_token;
  }

  getAuthorisationToken(): any {
    return this.userDetails.authorisation_token;
  }

  get isRoleLearner(): boolean {
    return this.userDetails.user_roles_human.some((role) =>
      LEARNER_ROLES.includes(role),
    );
  }
  get isRoleLearnerDev(): boolean {
    return this.userDetails.user_roles_human.some((role) =>
      LEARNER_dev_ROLES.includes(role),
    );
  }
  get isElsAdmin(): boolean {
    return this.userDetails.user_roles_human.includes('Els Administrator');
  }
}
